import React from 'react'
import { Link } from 'react-router-dom';

const Glimpse = () => {
  return (
    <div className="xl:mx-auto bg-black xl:container  2xl:px-20 px-6 md:py-20">
      <h1 className=" text-2xl md:text-5xl font-semibold py-[7%] md:py-0 leading-10 text-[#eb9729] text-center">
        Glimpses from Elite Runway Week Season 3
      </h1>
      <div className="grid md:grid-cols-3  md:mt-16 xl:gap-6 gap-4">
        <div className="">
          <img
            src="/erw3/DSC_0804.JPG"
            className=" object-cover border-indigo-700 border-[2px] rounded-lg object-center rounded-t-md"
            alt="woman in black dress"
          />
        </div>

        <div className="">
          <img
            src="/erw3/005A4927.JPG"
            className=" object-cover border-indigo-700 border-[2px] rounded-lg object-center rounded-t-md"
            alt="woman in black dress"
          />
        </div>
        <div className="">
          <img
            src="/erw3/DSC_0809.JPG"
            className=" object-cover border-indigo-700 border-[2px] rounded-lg object-center rounded-t-md"
            alt="woman smiling"
          />
        </div>
        <div className="">
          <img
            src="/erw3/DSC08524.JPG"
            className=" object-cover border-indigo-700 border-[2px] rounded-lg object-center rounded-t-md"
            alt="woman smiling"
          />
        </div>
        <div className="bg-indigo-700 rounded-md  flex flex-col items-center justify-center md:py-0 py-12">
          <h3 className="text-4xl font-semibold leading-6 text-center text-white">
            #ERW3
          </h3>
          <p className="lg:w-80 lg:px-0 px-4 text-base leading-6 text-justify text-white mt-6">
          Experience the fusion of high fashion and sustainability at Elite Runway Week 2024. This year's theme, "Embracing Sustainable Elegance," showcases the innovation and creativity of designers committed to eco-friendly practices. Join us for a week of exquisite collections that redefine luxury in the fashion world, proving that style and sustainability can coexist harmoniously.
          </p>
        </div>
        <div className="">
          <img
            src="/erw3/DSC_0728.JPG"
            className=" object-cover border-indigo-700 border-[2px] rounded-lg object-center rounded-t-md"
            alt="woman smiling"
          />
        </div>
        <div className="">
          <img
            src="/erw3/005A4815.JPG"
            className=" object-cover border-indigo-700 border-[2px] rounded-lg object-center rounded-t-md"
            alt="woman smiling"
          />
        </div>
        <div className="">
          <img
            src="/erw3/nehaallei.JPG"
            className=" object-cover border-indigo-700 border-[2px] rounded-lg object-center rounded-t-md"
            alt="woman smiling"
          />
        </div>
        <div className="">
          <img
            src="/erw3/005A4533.JPG"
            className=" object-cover border-indigo-700 border-[2px] rounded-lg object-center rounded-t-md"
            alt="woman smiling"
          />
        </div>
      </div>
      <div className="flex items-center justify-center pt-[3%]">
        <Link
          to="/Gallery"
          className="relative mt-8 py-4  bg-white rounded-lg px-10 sm:px-8 xl:px-12 flex justify-center items-center text-lg hover:-translate-y-1 md:text-xl  leading-4 md:leading-none text-white transition font-bold duration-500 ease-in-out "
        >
          <p className="relative  text-black z-10">View All</p>
        </Link>
      </div>
    </div>
  );
}

export default Glimpse