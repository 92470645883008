import React from 'react'
import Home from './Home'
// import Models from './Models'
import About from './About';
import Elevate from './Elevate';
import Glimpse from './Glimpse';
import Show from './Show';
import Paterner from './Paterner';

const index = () => {
  return (
    <div className="bg-black">
      <Home />
      {/* <Models /> */}
      <About />
      <Elevate />
      <Glimpse />
      <Show />
      <Paterner />
    </div>
  );
}

export default index