import React from 'react'

const Gallery = () => {
     const Img = [
       "/erw3/nehaallei.JPG",
       "/erw3/005A4927.JPG",
       "/erw3/DSC08524.JPG",
       "/erw3/005A4533.JPG",
       "/erw3/DSC_0804.JPG",
       "/erw3/DSC_0728.JPG",
       "/erw3/DSC_0809.JPG",
       "/erw3/005A4754.JPG",
     ];
  return (
    <div className="py-[7%] bg-black text-white">
      <div>
        <div className="grid md:grid-cols-4 gap-5 my-[5%] mx-[5%]">
          {Img.map((item, index) => (
            <img
              src={item}
              alt=""
              className="border-[#CF7600] border-[2px] rounded-lg"
            />
          ))}
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Gallery