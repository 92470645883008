import React, { useState, useEffect } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const NavBar = () => {
   const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
    const [scrollBackground, setScrollBackground] = useState(false);
     useEffect(() => {
       const handleScroll = () => {
         if (window.scrollY > 150) {
           setScrollBackground(true);
         } else {
           setScrollBackground(false);
         }
       };

       window.addEventListener("scroll", handleScroll);

       return () => {
         window.removeEventListener("scroll", handleScroll);
       };
     }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const closeMenu = () => {
  //   setIsMenuOpen(false);
  // };

  return (
    <nav
      className={` fixed  z-30  w-full ${
        scrollBackground ? "bg-black transition-colors duration-700" : ""
      } transition-colors duration-500`}
    >
      <div className="container md:px-4  md:flex md:items-center md:justify-between ">
        <div className=" p-2 flex justify-between items-center md:ml-[3%]">
          <Link to="/">
            <img
              src="erwlogo.png"
              alt="logo"
              className="w-16 mt-2 md:w-32 "
            />
          </Link>
          <button
            className="md:hidden text-white focus:outline-none"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <MdClose size={30} /> : <BiMenuAltRight size={30} />}
          </button>
        </div>

        <div
          className={`hidden md:flex items-center justify-around gap-10 md:mr-[3%]`}
        >
          <ul className="md:flex gap-10  md:justify-end">
            <li>
              <Link
                to="/"
                className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                  location.pathname === "/"
                    ? "text-[#CF7600] border-b-2"
                    : "text-white hover:text-[#CF7600] border-b-2"
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/AboutUs"
                className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                  location.pathname === "/AboutUs"
                    ? "text-[#CF7600] border-b-2"
                    : "text-white hover:text-[#CF7600] border-b-2"
                }`}
              >
                About Us
              </Link>
            </li>
            <li
              onClick={toggleDropdown}
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              <Link
                className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                  location.pathname === "/Models" ||
                  location.pathname === "/Designers"
                    ? "text-[#CF7600] border-b-2"
                    : "text-white hover:text-[#CF7600] border-b-2"
                }`}
              >
                Talent{" "}
                <span>
                  <FaAngleDown />
                </span>
              </Link>
              {dropdownOpen && (
                <div className="absolute   bg-white border-[2px] shadow-lg -ml-7  w-40 r">
                  <Link
                    to="/Models"
                    className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                      location.pathname === "/Models"
                        ? "text-[#CF7600]  px-4 py-2 border-b-2"
                        : "text-white bg-black px-4 py-2 hover:bg-white hover:text-[#CF7600] border-b-2"
                    }`}
                  >
                    Models
                  </Link>
                  <p className="bg-white h-[2px]"></p>
                  <Link
                    to="/Designers"
                    className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer flex items-center ${
                      location.pathname === "/Designers"
                        ? "text-[#CF7600]  px-4 py-2 border-b-2"
                        : "text-white bg-black px-4 py-2 hover:bg-white hover:text-[#CF7600] border-b-2"
                    }`}
                  >
                    Designers
                  </Link>
                </div>
              )}
            </li>
            {/* <li>
              <Link
                to="/Media_Gallery"
                className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                  location.pathname === "/Media_Gallery"
                    ? "text-[#CF7600] border-b-2"
                    : "text-white hover:text-[#CF7600] border-b-2"
                }`}
              >
                Media
              </Link>
            </li> */}
            <li>
              <Link
                to="/Gallery"
                className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                  location.pathname === "/Gallery"
                    ? "text-[#CF7600] border-b-2"
                    : "text-white hover:text-[#CF7600] border-b-2"
                }`}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/ContactUs"
                className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                  location.pathname === "/ContactUs"
                    ? "text-[#CF7600] border-b-2"
                    : "text-white hover:text-[#CF7600] border-b-2"
                }`}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link className="focus:outline-none bg-white text-black px-5 py-3 rounded-3xl hover:text-[#CF7600] border-b-2 -mt-2 text-lg border-transparent font-medium cursor-pointer  flex items-center">
                Register Now
              </Link>
            </li>
          </ul>
        </div>
        {isMenuOpen && (
          <div className={`md:hidden px-5 bg-black py-[10%] `}>
            <ul className="space-y-5">
              <li>
                <Link
                  to="/"
                  className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                    location.pathname === "/"
                      ? "text-[#CF7600] border-b-2"
                      : "text-white hover:text-[#CF7600] border-b-2"
                  }`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/AboutUs"
                  className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                    location.pathname === "/AboutUs"
                      ? "text-[#CF7600] border-b-2"
                      : "text-white hover:text-[#CF7600] border-b-2"
                  }`}
                >
                  About Us
                </Link>
              </li>
              <li
                onClick={toggleDropdown}
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <Link className="focus:outline-none text-lg text-white border-transparent font-medium cursor-pointer  flex items-center ">
                  Talent{" "}
                  <span>
                    <FaAngleDown />
                  </span>
                </Link>
                {dropdownOpen && (
                  <div className=" shadow-lg space-y-5 mt-5 w-40 ">
                    <Link
                      to="/Models"
                      className={`focus:outline-none pl-4 text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                        location.pathname === "/Models"
                          ? "text-[#CF7600]   border-b-2"
                          : "text-white  hover:bg-white hover:text-[#CF7600] border-b-2"
                      }`}
                    >
                      Models
                    </Link>
                    {/* <Link
                      to="/Designers"
                      className={`focus:outline-none pl-4 text-lg border-transparent font-medium cursor-pointer flex items-center ${
                        location.pathname === "/Designers"
                          ? "text-[#CF7600]   border-b-2"
                          : "text-white  hover:bg-white hover:text-[#CF7600] border-b-2"
                      }`}
                    >
                      Designers
                    </Link> */}
                  </div>
                )}
              </li>
              {/* <li>
                <Link
                  to="/Media_Gallery"
                  className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                    location.pathname === "/Media_Gallery"
                      ? "text-[#CF7600] border-b-2"
                      : "text-white hover:text-[#CF7600] border-b-2"
                  }`}
                >
                  Media
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/Gallery"
                  className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                    location.pathname === "/Gallery"
                      ? "text-[#CF7600] border-b-2"
                      : "text-white hover:text-[#CF7600] border-b-2"
                  }`}
                >
                  Gallery
                </Link>
              </li> */}
              <li>
                <Link
                  to="/ContactUs"
                  className={`focus:outline-none text-lg border-transparent font-medium cursor-pointer  flex items-center ${
                    location.pathname === "/ContactUs"
                      ? "text-[#CF7600] border-b-2"
                      : "text-white hover:text-[#CF7600] border-b-2"
                  }`}
                >
                  Contact Us
                </Link>
              </li>
              <li className="w-44">
                <Link className="focus:outline-none bg-white text-black px-7 py-3 rounded-3xl hover:text-[#CF7600] border-b-2 -mt-2 text-lg border-transparent font-medium cursor-pointer  flex items-center">
                  Register Now
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
