import React from 'react'

const Privacy = () => {
  return (
    <div className="mx-[10%] bg-black text-white py-[5%]">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-7">
          <div className="text-[#CF7600]  text-[40px] font-medium text-center">
            Privacy Policy - Elite Runway Week
           </div>

          <p className="text-lg font-medium text-justify">
            At
            <span className="text-[#CF7600] mx-2">
              Elite Runway Week
            </span>
            , we value your privacy and are committed to protecting your
            personal information. This Privacy Policy outlines how we collect,
            use, disclose, and safeguard the information you provide when using
            our website and related services.
          </p>
          <p className="text-lg font-medium">
            By accessing or using our website, you acknowledge that you have
            read, understood, and agree to be bound by the terms of this Privacy
            Policy. If you do not agree with this Privacy Policy, please refrain
            from using our website.
          </p>
        </div>
        <div className="space-y-3">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Information We Collect
          </h1>
          <div className="inline text-lg font-medium text-justify gap-1">
            <span className="text-[#ff9900]"> Personal Information: </span>
            <span className="text-justify">
              When you visit our website, we may collect personally identifiable
              information that you voluntarily provide to us, such as your name,
              email address, contact number, and any other information you
              choose to provide when contacting us or signing up for
              newsletters.
            </span>
          </div>
          <div className="space-y-3">
            {" "}
            <div className="flex text-lg font-medium text-justify gap-1">
              <h1 className="text-[#ff9900] text-xl font-medium">
                Use of Information
              </h1>
            </div>
            <div className="inline space-y-3 text-lg font-medium text-justify gap-1">
              <span className="text-[#ff9900] mr-1">
                2.1 Personal Information:
              </span>
              <span className="text-justify">
                We may use the personal information you provide to us for the
                following purposes:
              </span>
            </div>
            <ul className="list-disc text-lg className='text-justify' font-medium ml-7">
              <li>
                To respond to your inquiries, provide customer support, and
                address your requests.
              </li>
              <li>
                To send you newsletters, updates, and promotional materials
                related to Elite Runway Week, but only if you
                have consented to receive such communications.
              </li>
              <li>
                To personalize your experience and improve our website based on
                your feedback and suggestions.
              </li>
              <li>
                To comply with legal obligations or enforce our Terms and
                Conditions.
              </li>
            </ul>
          </div>
          <div className="inline space-y-3 text-lg font-medium text-justify gap-1">
            <span className="text-[#ff9900] mr-1">
              2.2 Non-Personal Information:
            </span>
            <span className="text-justify">
              We may use non-personal information for analytical and statistical
              purposes to improve our website’s functionality, user experience,
              and content. This information helps us understand how users
              interact with our website and allows us to enhance our services.
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Sharing of Information
          </h1>
          <div className="inline space-y-3 text-lg font-medium text-justify ">
            <span className="text-[#ff9900] mr-1">
              3.1 Third-Party Service Providers:
            </span>
            <span className="text-justify">
              We may share your personal information with trusted third-party
              service providers who assist us in operating our website and
              delivering our services. These service providers are obligated to
              use your information only for the purposes specified by us and in
              compliance with applicable laws.
            </span>
          </div>
          <br />
          <div className="inline text-lg font-medium text-justify gap-1">
            <span className="text-[#ff9900] mr-1">3.2 Legal Requirements:</span>
            <span className="text-justify">
              We may disclose your personal information if required to do so by
              law or in response to a valid legal request, such as a court order
              or government agency’s request.
            </span>
          </div>
        </div>{" "}
        <div className=" text-lg font-mediumtext-justify">
          3.3 Consent: We will obtain your consent before sharing your personal
          information with third parties for marketing purposes.
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">Data Security</h1>
          <div className="text-justify">
            We implement reasonable security measures to protect your personal
            information from unauthorized access, disclosure, alteration, or
            destruction. However, please note that no method of transmission
            over the Internet or electronic storage is 100% secure, and we
            cannot guarantee absolute security.
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Third-Party Links
          </h1>
          <div className="text-justify">
            Our website may contain links to third-party websites or services.
            We do not have control over the privacy practices or content of
            these third-party websites. We encourage you to review the privacy
            policies of these websites before providing any personal
            information.
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Children’s Privacy
          </h1>
          <div className="text-justify">
            Our website is not intended for children under the age of 13. We do
            not knowingly collect or solicit personal information from children
            under 13. If you believe that we may have collected personal
            information from a child under 13, please contact us immediately,
            and we will take appropriate steps to remove such information from
            our records.
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Changes to the Privacy Policy
          </h1>
          <div className="text-justify">
            We reserve the right to modify or update this Privacy Policy at any
            time. Any changes will be effective immediately upon posting the
            revised Privacy Policy on our website. We encourage you to review
            this Privacy Policy periodically for any updates.
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">Contact Us</h1>
          <div className="text-justify">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the practices.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy