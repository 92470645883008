import React from 'react'

const Elevate = () => {
  return (
    <div className="overflow-y-hidden bg-black">
      <div className="relative flex justify-center items-center">
        <img
          className="w-full h-[50vh] md:h-auto object-cover opacity-[50%] "
          src="/erw3/005A4435.JPG"
          alt="desktop bg"
        />

        <div className="absolute flex justify-center items-center flex-col px-8 py-12 md:py-14 xl:py-20 ">
          <h1 className=" text-[#eb9729] shadow-3xl sm:text-lg xl:text-4xl text-center font-semibold leading-1 md:leading-2 xl:leading-3 ">
          Elite Runway Week: A Sustainable Fashion Revolution
          </h1>
          <p className="  shadow-3xl md:text-2xl mt-2 md:mt-6 leading-normal md:mx-[7%] text-center text-white">
          Discover how creativity and sustainability come together on the runway, featuring collections from leading designers committed to ethical practices.
          </p>
          <button className="relative mt-8 py-4 bg-white rounded-lg px-10 sm:px-8 xl:px-12 flex justify-center items-center text-lg hover:-translate-y-1 md:text-lg font-medium leading-4 md:leading-none text-white transition  duration-500 ease-in-out ">
            <p className="relative  text-black z-10">Register Now</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Elevate