import React from 'react'
import Home from './Home'
import Media from './Media'

const index = () => {
  return (
    <div className="bg-black">
      <Home />
      <Media />
    </div>
  );
}

export default index