import React from 'react'

const HeroPage = () => {
  return (
        <div className='py-[15%]'
          style={{
            backgroundImage: `url("/erw3/DSC_0974.jpg")`,
            backgroundSize: "cover",
            opacity:"65%",
            width: "100%",
            height: "100%",
             position: "relative",
          }}
        >
          {/* <h1 className=" text-[#ffff] sm:text-2xl xl:text-5xl pt-[10%] pl-[10%] font-semibold leading-1 md:leading-2 xl:leading-3 ">
            About Us
          </h1> */}
        </div>
  );
}

export default HeroPage