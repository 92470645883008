import React from 'react'

const About = () => {
  return (
    <div className="overflow-y-hidden bg-black mx-[5%]">
      <div className="container mx-auto bg-black md:pt-0 ">
        <div className="flex flex-wrap rounded-lg bg-black">
          <div className="xl:w-2/5 lg:w-2/5 sm:w-full bg-bg-black  xl:py-24 px-2 xl:px-3 text-center xl:text-left lg:text-left md:text-left sm:text-left xl:rounded-tl xl:rounded-bl">
            <img src="/erw3/DSC09281.JPG" alt="" />
          </div>
          <div className="lg:w-3/5 xl:w-3/5  bg-black pl-4 pb-4 pr-4 xl:pr-10 xl:pl-0 xl:pt-10 xl:pb-10 xl:rounded-tr xl:rounded-br relative">
            <div>
              {" "}
              <figure className="visible  bg-black">
                <div className="relative bg-black">
                  <div className="md:pt-12 pt-16 pt-0 bg-black text-white md:pl-[5%]">
                    <p className="text-3xl text-[#CF7600] font-bold">
                      About Us
                    </p>
                    <br />
                    <p className="text-white md:text-lg text-justify pb-2">
                    With a focus on eco-conscious practices, Elite Runway Week celebrates designers who innovate with ethics in mind. Our mission is to spotlight innovative designers and brands that are paving the way for a more sustainable future. By showcasing collections that prioritize eco-friendly materials, ethical production processes, and fair trade practices, we aim to inspire both industry professionals and consumers to embrace conscious fashion choices.
                    </p>
                    <p className="text-white md:text-lg text-justify">

                    From groundbreaking designs that utilize recycled and organic materials to in-depth conversations with industry leaders about the latest in sustainable fashion technology, ELITE RUNWAY WEEK is a celebration of style that respects our planet.
                  </p>
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );}


export default About