import React from 'react'

const Designers = () => {
    const Img = [
      {
        img: "/erw3/DSC09297.jpg",
        name: "Rosy Ahluwalia",
      },
      {
        img: "/erw3/DSC08959.jpg",
        name: "Mukesh Dubey",
      },
      {
        img: "/erw3/005A4584.jpg",
        name: "Allie Sharma",
      },
      {
        img: "/erw3/DSC09019.jpg",
        name: "Varma Sons",
      },
      {
        img: "/erw3/005A4497.jpg",
        name: "Kalpna Gautam",
      },
      {
        img: "/erw3/005A4673.jpg",
        name: "Vipin Agrawal & Mamta Malik",
      },
      {
        img: "/erw3/005A4431.jpg",
        name: "Sneha",
      },
      {
        img: "/erw3/DSC08809.jpg",
        name: "Shoppers Stop",
      },
      {
        img: "/erw3/DSC08206.jpg",
        name: "Lal Bahadur Shastri Institute",
      },
     
    ];
  return (
    <div className="mx-auto bg-black text-white container lg:px-20 md:px-6 px-4 py-12">
      <div className="flex items-center justify-center flex-col">
        {/* <h1 className="lg:text-2xl text-lg font-bold md:leading-10 text-white text-center">
          Unlock your creativity and redefine possibilities with our
          designer-centric hub
        </h1>
        <p className="md:text-lg text-base md:text-center text-justify leading-7  text-[#CF7600] font-medium mt-5 ">
          Step into the world of limitless creativity on our designer page.
          Discover a treasure trove of resources, tools, and inspiration
          tailored specifically for designers. Unleash your imagination,
          collaborate with fellow visionaries, and elevate your designs to new
          heights in this vibrant community of artistic minds
        </p> */}
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 pb-5 md:pb-12 pt-5 md:pt-20">
        {Img.map((item, index) => (
          <div className="w-full flex items-center justify-center flex-col">
            <div className="  -mb-10  relative ">
              <img
                src={item.img}
                alt="girl smilling"
                className="w-[100%] border-indigo-700 border-[2px] rounded-lg h-[100%] object-cover "
              />
            </div>
            <div className="pb-8 pt-14 bg-indigo-700 rounded-md w-full ">
              <p className="text-xl font-semibold leading-5 text-center text-white">
                {item.name}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Designers