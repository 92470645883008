import React from 'react'
import { RiCopyrightFill } from "react-icons/ri";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className=" bg-[#000000] py-7 xl:px-20 lg:px-12 sm:px-6 px-4">
      <div className="md:flex items-center justify-between">
        <div className="flex items-center text-white">
          <RiCopyrightFill />
          <div className="border-l border-white ml-2 pl-2">
            <p className="text-base leading-4">
              2024 Elite Runway Week
            </p>
          </div>
        </div>
        <div className="flex items-center text-white gap-x-3 md:mt-0 mt-6">
          <Link to="/Terms&Condition"> Terms & Condition</Link>
          <span>|</span>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer