import React from 'react'
import Home from "./Home"
import Condition from './Condition'

const index = () => {
  return (
    <div className="bg-black">
      <Home />
      <Condition />
    </div>
  );
}

export default index