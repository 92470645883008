import React from 'react'

const Models = () => {
    const Img = ["zander.jpg",
     
     "saud.png",
     
    
     "paras.jpg",
     "sunny.jpg",
    
];
  return (
    <div className="py-[7%] bg-black text-white">
      <div className="text-center space-y-5">
      <h1 className=" text-2xl md:text-5xl font-semibold py-[7%] md:py-0 leading-10 text-[#eb9729] text-center">
       Top Models in ERW
      </h1>
        {/* <h1 className=" text-xl text-center md:text-2xl font-medium">
          Explore an array of innovative and diverse models designed to cater to
          your unique needs
        </h1> */}
        {/* <p className="text-[#CF7600] mx-[5%]  text-justify md:text-center md:text-lg font-medium">
          Welcome to our models page, where you can discover a captivating
          collection of cutting-edge models. From fashion and architecture to AI
          and finance, our meticulously curated selection showcases the
          versatility and ingenuity of our creations.
        </p> */}
      </div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-4  gap-5 my-[5%] mx-[5%]">
          {Img.map((item, index) => (
            <img
              src={item}
              alt=""
              className="border-[#CF7600] border-[2px] rounded-lg"
            />
          ))}
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Models