import React from 'react'
import { Routes, Route } from "react-router-dom";
import ScrollToTop from './ScrollToTop'
import NavBar from './components/NavBar'
import Home from './components/Home'
import Footer from './components/Footer';
import AboutUs from './components/About';
import Models from './components/Model';
import Designers from "./components/Designers​"
import Media from './components/Media';
import ContactUs from './components/ContactUs';
import TermsCondition from "./components/TermsCondition"
import PrivacyPolicy from "./components/PrivacyPolicy"
import Gallery from "./components/Gallery"
// import Nav from './components/Nav';
const Routing = () => {
  return (
    <div>
      <ScrollToTop />
      {/* <Nav/> */}
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/AboutUs" element={<AboutUs />} />
        <Route exact path="/Models" element={<Models />} />
        <Route exact path="/Designers" element={<Designers />} />
        <Route exact path="/Media_Gallery" element={<Media />} />
        <Route exact path="/ContactUs" element={<ContactUs />} />
        <Route exact path="/Terms&Condition" element={<TermsCondition />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/Gallery" element={<Gallery />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default Routing