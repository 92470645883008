import React from 'react'

const About = () => {
  return (
    <div className="mx-[10%] text-white py-[5%] bg-black">
      <h1 className="text-center  text-xl font-medium">
     About Us
      </h1>
      <div className="flex text-white flex-col gap-10">
        <div className="flex flex-col gap-7">
          <div className="text-[#CF7600] mb-0 md:mb-[3%] text-[30px] md:text-[50px] font-medium text-center">
            Elite Runway Week
          </div>

          <p className="md:text-lg font-medium text-justify">
            As the ultimate platform for fashion models and designers, we bring
            together a dazzling fusion of creativity, style, and innovation. Get
            ready to embark on a journey where fashion takes center stage and
            dreams come to life.Drawing inspiration from the world’s most iconic
            fashion events, the{" "}
            <span className="text-[#CF7600]">
            Elite Runway Week
            </span>{" "}
            is a spectacle like no other. Immerse yourself in the vibrant
            atmosphere as renowned designers from across the globe unveil their
            latest collections, pushing the boundaries of style and redefining
            fashion trends. From elegant couture to avant-garde creations, our
            runway showcases an extraordinary display of sartorial brilliance.
          </p>
          <p className="md:text-lg font-medium text-justify">
            But the{" "}
            <span className="text-[#CF7600]">
            Elite Runway Week
            </span>{" "}
            is more than just a glamorous showcase. It is an immersive
            experience that celebrates the artistry, craftsmanship, and vision
            of the fashion industry. With each step down the runway, our models
            breathe life into the designs, captivating the audience with their
            poise and confidence. Witness their magnetic presence and be swept
            away by the allure of high fashion.
          </p>
          <p className="md:text-lg font-medium text-justify">
            Beyond the runway, the{" "}
            <span className="text-[#CF7600]">
            Elite Runway Week
            </span>{" "}
            offers a myriad of engaging experiences. Engage in interactive
            workshops and masterclasses led by industry experts, where you can
            glean insights and learn the secrets of the trade. Immerse yourself
            in curated exhibitions, featuring cutting-edge technology,
            sustainable fashion initiatives, and emerging talent. Connect with
            like-minded individuals, build valuable relationships, and pave your
            way to success in the fashion world.{" "}
          </p>
          <p className="md:text-lg font-medium text-justify">
            Get ready to witness the convergence of beauty, creativity, and
            glamour at the{" "}
            <span className="text-[#CF7600]">
            Elite Runway Week
            </span>
            . Experience the thrill of being part of a global fashion movement,
            where trends are set, boundaries are shattered, and dreams are
            realized.
          </p>
        </div>
        <div>
          <div className="text-[#CF7600] mb-[3%] text-[50px] font-medium text-center">
            Our Mission
           </div>
          <p className="md:text-lg text-base font-medium text-justify">
            
            Our Mission is to spearhead a
sustainable fashion movement,
prioritizing ethical practices and
environmental responsibility. We're
dedicated to challenging norms,
inspiring innovation, and fostering
conscious consumerism.
Through our actions, we aim to
redefine industry standards,
championing both style and
sustainability.
          </p>
        </div>
        <div>
          <div className="text-[#CF7600] mb-[3%] text-[50px] font-medium text-center">
            Our Vision
          </div>
          <p className="md:text-lg font-medium text-justify">
            The vision of{" "}
            <span className="text-[#CF7600]">
            Elite Runway Week
            </span>{" "}
            is to become a globally recognized symbol for promotion of sustainable fashion. ERW aims to inspire fashion forwardness, celebrate
            cultural diversity, foster industry collaboration, and empower
            emerging designers working on Eco friendly garments. Through its vision, ERW seeks to elevate sustainable
            fashion, create impactful connections, and shape the future of the
            sustainability in fashion industry.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About