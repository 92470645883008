import React from 'react'
import Home from './Home'
import ContactUs from './ContactUs'

const index = () => {
  return (
    <div className="bg-black">
      <Home />
      <ContactUs />
    </div>
  );
}

export default index