import React from 'react'
import backgroundVideo from "../../assets/erw.mp4";
const Home = () => {
  return (
    <div className="">
      <video
        className="w-[100vw]  h-[35vh] md:h-[100vh] object-cover  inset-0"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
    </div>
  );
}

export default Home