import React from 'react'

const Media = () => {
   const Img = ["me1.jpg",
    "amarujala.png",
    "me3.jpg",
    "me4.jpg",
    "me5.jpg",
    "me6.jpg",
    "me7.jpg",
    "me8.jpg",
    "me9.jpg",
    "me10.jpg",
    "me11.jpg",
    "me12.jpg",
    "me13.jpg",
    "me14.jpg",
    "me15.jpg",
    "me16.jpg",
    "me17.jpg",
];
   return (
     <div className="py-[7%] bg-black text-white">
      <div>
         <div className="grid md:grid-cols-4  gap-5 my-[5%] mx-[5%]">
           {Img.map((item, index) => (
             <img
               src={item}
               alt=""
               className="border-[#CF7600] border-[2px] rounded-lg"
             />
           ))}
         </div>
         <div></div>
       </div>
     </div>
   );
}

export default Media