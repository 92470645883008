import React from 'react'
import HeroPage from './HeroPage'
import About from './About'


const index = () => {
  return (
    <div className="bg-black">
      <HeroPage />
      <About />
    </div>
  );
}

export default index