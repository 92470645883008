import React from 'react'

const Show = () => {
  return (
    <div className="overflow-y-hidden">
      <div className="xl:mx-auto xl:container   md:px-6 px-4 py-7 md:py-20">
        <div className="text-center">
          <p className="lg:text-4xl mb-8 text-3xl font-extrabold leading-9 text-[#CF7600]">
          Elite Runway Week 2025: Coming Soon
          </p>
        </div>
        <div className="lg:flex items-center justify-center lg:space-x-12 2xl:space-x-6 mx-[3%]">
          <div className=" w-full md:4/6">
            <div className="mt-6 md:mt-10 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 lg:mt-6 2xl:mt-12">
              <div className="flex items-start flex-col  pt-4">
                <h2 className="text-2xl font-semibold leading-4 text-[#CF7600]">
                Eco-Couture Catwalk
                </h2>
                <p className=" md:text-lg leading-6 mt-2 text-justify text-white">
                Witness breathtaking collections from top designers who have mastered the art of eco-friendly fashion. Featuring garments made from recycled materials, organic fabrics, and zero-waste techniques, this catwalk demonstrates that sustainability and high fashion can create stunning synergy.
                </p>
              </div>
              <div className="flex items-start flex-col  pt-4">
                <h2 className="text-2xl font-semibold leading-4 text-[#CF7600]">
                Innovative Materials Showcase
                </h2>
                <p className=" md:text-lg leading-6 mt-2 text-justify text-white">
                Explore an interactive exhibit highlighting the latest advancements in sustainable textiles. Discover innovative materials such as lab-grown leather, biodegradable fibers, and plant-based dyes, and learn how these cutting-edge solutions are shaping the future of fashion.
                </p>
              </div>
              <div className="flex items-start flex-col pt-4">
                <h2 className="text-2xl font-semibold leading-4 text-[#CF7600]">
                Green Glamour Panel Discussions
                </h2>
                <p className="l md:text-lg leading-6 text-justify mt-2 text-white">
                Join thought leaders, designers, and sustainability experts in engaging panel discussions on the challenges and opportunities within the sustainable fashion industry. Topics include ethical production practices, circular fashion models, and the impact of consumer behavior on the environment.
                </p>
              </div>
              <div className="flex items-start flex-col  pt-4">
                <h2 className="text-2xl font-semibold  text-[#CF7600] leading-4 ">
                Upcycled Fashion Workshop
                </h2>
                <p className=" md:text-lg leading-6 text-justify mt-2 text-white">
                Participate in hands-on workshops led by skilled artisans and designers who specialize in upcycling. Learn techniques to transform old garments into stylish new pieces, promoting a culture of reuse and creativity. Attendees will leave with their own unique, upcycled fashion items, fostering a personal connection to sustainable practices.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="w-full mt-20 md:w-1/4">
            <img
              src="show.jpeg"
              alt="ongoing meeting"
              className="w-80 obejct-cover border-indigo-700 border-[2px] rounded-lg object-center object-fill h-[70vh]"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Show