import React from 'react'
import {MdPhoneIphone} from "react-icons/md"
import { HiMail } from "react-icons/hi";
import {  FaInstagram } from "react-icons/fa";

const ContactUs = () => {
  return (
    <div className="pb-7 pt-8 md:pt-20 bg-black text-white">
      <div className=" mx-auto   ">
        <div className="flex md:flex-row flex-col mx-[3%] items-center py-[3%] justify-center px-4">
          <div className="md:w-[60%] ">
            <img
              src="/erw3/DSC08530.jpg"
              alt=""
              className="border-indigo-700 border-[2px] rounded-lg"
            />
            <div className="space-y-5">
              <h1 className="md:text-3xl p-[4%] text-center">
                Lead the way for Eco Friendly Fashion Designs
              </h1>
              <p className="md:text-xl flex gap-3">
                <span>
                  <MdPhoneIphone size={30} />
                </span>
                <span className="">
                  +91 8630062102
                </span>
              </p>
              <p className="md:text-xl flex gap-3">
                <span>
                  <HiMail size={30} />
                </span>
                <span>info@eliterunwayweek.com</span>
              </p>
              <p className="md:text-xl flex gap-3">
                <span>
                  <FaInstagram size={30} />
                </span>
                <span>elite_runway_week</span>
              </p>
            </div>
          </div>
          <div className="bg-white border-indigo-700 border-[2px]  shadow rounded-lg mt-5 md:-mt-[7.5%] p-5 md:w-[40%] sm:p-12 w-full">
            <h1 className="text-xl text-center sm:text-4xl font-bold leading-normal text-gray-800">
              Get In Touch
            </h1>
            {/* <p className="text-xl text-black sm:text-4xl font-bold text-center">
              Feel Free to Reach us
            </p> */}
            <div className=" sm:mt-16 flex flex-col w-full space-y-5">
              <div className=" flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Name *
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input name"
                  type="name"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input  name"
                />
              </div>
              <div className=" flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Email *
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input name"
                  type="name"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input  name"
                />
              </div>
              <div className=" flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Contact Number *
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input name"
                  type="name"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input  name"
                />
              </div>
              <div className=" flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Message 
                </label>
                <textarea
                  tabIndex={0}
                  arial-label="Please input name"
                  type="name"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input  name"
                />
              </div>

              <button className="flex bg-indigo-700 rounded justify-center items-center py-2 sm:py-4 text-xs sm:text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 hover:bg-indigo-600">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs