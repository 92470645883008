import React from 'react'

const Condition = () => {
  return (
    <div className="mx-[10%] bg-black text-white py-[5%]">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-7">
          <div className="text-[#CF7600]  text-[40px] font-medium text-center">
            TERMS & CONDITIONS
            </div>

          <p className="text-lg font-medium text-justify">
           
            By
            accessing or using our website, you acknowledge that you have read,
            understood, and agree to be bound by these Terms. If you do not
            agree with these Terms, please refrain from using our website.
          </p>
        </div>
        <div className="space-y-3">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Use of the Website
          </h1>
          <div className="flex text-lg font-medium text-justify gap-1">
            <span className="text-[#ff9900]">1.1 Eligibility : </span>
            <span className="text-justify">
              You must be at least 18 years old to use our website. Using our
              website, you represent and warrant that you are at least 18.
            </span>
          </div>
          <div className="space-y-3">
            {" "}
            <div className="flex text-lg font-medium text-justify gap-1">
              <span className="text-[#ff9900]">1.2 User Conduct: </span>
              <span>When using our website, you agree to:</span>
            </div>
            <ul className="list-decimal text-lg className='text-justify' font-medium ml-7">
              <li>
                a) Comply with all applicable laws, regulations, and these
                Terms.
              </li>
              <li>
                b) Use our website for lawful purposes only and not engage in
                any activity that may harm, disrupt, or impair the website or
                its functionality.
              </li>
              <li>
                c) Respect the intellectual property rights of ERW and third
                parties.
              </li>
              <li>
                d) Do Not attempt to gain unauthorized access to any part of our
                website, accounts, or computer systems.
              </li>
            </ul>
          </div>
          <div className="inline space-y-3 text-lg font-medium text-justify gap-1">
            <span className="text-[#ff9900] mr-1">1.3 Account Creation :</span>
            <span className="text-justify">
              Some features of our website may require you to create an account.
              When creating an account, you agree to provide accurate, current,
              and complete information. You are responsible for maintaining the
              confidentiality of your account information and for all activities
              that occur under your account.
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Intellectual Property Rights
          </h1>
          <div className="inline space-y-3 text-lg font-medium text-justify ">
            <span className="text-[#ff9900] mr-1">2.1 Ownership :</span>
            <span className="text-justify">
              All content and materials available on our website, including
              text, graphics, logos, images, audio clips, videos, and software,
              are the property of ERW or its licensors and are
              protected by applicable intellectual property laws.
            </span>
          </div>
          <br />
          <div className="inline text-lg font-medium text-justify gap-1">
            <span className="text-[#ff9900] mr-1">2.2 Limited License :</span>
            <span className="text-justify">
              We grant you a limited, non-exclusive, non-transferable, and
              revocable license to access and use our website solely for your
              personal, non-commercial use. You may not reproduce, distribute,
              modify, transmit, perform, or display any portion of our website
              or its content without our prior written consent.
            </span>
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Disclaimer of Warranties
          </h1>
          <div className="text-justify">
            3.1 Our website and its content are provided on an “as-is” and
            “as-available” basis. We make no representations or warranties of
            any kind, whether express, implied, or statutory, regarding the
            accuracy, completeness, reliability, or availability of our website
            or its content.
          </div>
          <div className="text-justify">
            3.2 We do not guarantee that our website will be uninterrupted,
            error-free, or free from viruses or other harmful components. You
            acknowledge that your use of our website is at your own risk.
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Limitation of Liability
          </h1>
          <div className="text-justify">
            4.1 To the fullest extent permitted by applicable law, ERW, its directors, officers, employees, affiliates, agents,
            contractors, and licensors shall not be liable for any direct,
            indirect, incidental, special, consequential, or exemplary damages,
            including but not limited to damages for loss of profits, data, or
            other intangible losses, arising out of or in connection with your
            use or inability to use our website.
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Indemnification
          </h1>
          <div className="text-justify">
            You agree to indemnify and hold ERW, its directors,
            officers, employees, affiliates, agents, contractors, and licensors
            harmless from and against any claims, losses, liabilities, expenses,
            damages, and costs, including reasonable attorneys’ fees, arising
            out of or in connection with your use of our website or any
            violation of these Terms.
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Modifications to the Terms
          </h1>
          <div className="text-justify">
            We reserve the right to modify or update these Terms at any time.
            Any changes will be effective immediately upon posting the revised
            Terms on our website. Your continued use of our website after the
            posting of any changes constitutes your acceptance of the modified
            Terms.
          </div>
        </div>
        <div className="text-lg font-medium">
          <h1 className="text-[#ff9900] text-xl font-medium">
            Governing Law and Jurisdiction
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Condition