import React from 'react'
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
// import { Autoplay } from "swiper"; 
const Paterner = () => {
    const Img = [
      "p1.png",
      "p5.png",
      "p6.png",
      "p7.png",
      "p8.png",
      "reliancetrendslogo.png"
    ];
  return (
    <div className="bg-black py-14">
      <div>
        <p className="lg:text-4xl text-3xl text-center font-extrabold leading-9 text-[#CF7600]">
         ASSOCIATIONS
        </p>
      </div>
      <div className="py-[7%] bg-black">
        <div>
          <div className="grid md:grid-cols-4 lg:grid-cols-6 grid-cols-2 gap-5   mx-[5%]">
            {Img.map((item, index) => (
              <img
                src={item}
                alt=""
                className=" object-fill w-40 h-40 bg-black rounded-full"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paterner


//  <div className="md:mx-[10%] bg-black mx-[30%]  py-16">
//         <Swiper
//           loop={true}
//           autoplay={{
//             delay: 2000,
//           }}
//           breakpoints={{
//             640: { slidesPerView: 1 },
//             778: { slidesPerView: 2 },
//             1024: { slidesPerView: 3 },
//             1224: { slidesPerView: 4 },
//             1324: { slidesPerView: 4 },
//             1524: { slidesPerView: 4 },
//           }}
//           modules={[Autoplay]}
//           className="mySwiper"
//         >
//           <SwiperSlide>
//             <img src="p1.png" alt="" className=" bg-black w-32" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src="p4.png" alt="" className="w-32" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src="p3.png" alt="" className="bg-black w-32" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src="p2.png" alt="" className="w-32" />
//           </SwiperSlide>

//           <SwiperSlide>
//             <img src="p5.png" alt="" className="w-32" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src="p6.png" alt="" className="w-32" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src="p7.png" alt="" className="w-32" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src="p8.png" alt="" className="bg-black w-32" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src="p9.png" alt="" className="w-32" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src="p10.png" alt="" className="w-32" />
//           </SwiperSlide>
//         </Swiper>
//       </div>